import TextSections from "../components/TextSections";

const sections = [
  `Мы специализируемся на разработке цифровых платформ и интегрированных комплексных решений в сфере образования, электронного издательства, машинного обучения, управления контентом.`,
  `Высокая инженерная культура, опыт и мировые стандарты качества процессов разработки позволяют нам не только создавать и поставлять собственные решения, но и вливаться в существующую команду заказчика, обогащая её своим опытом и знаниями.`,
  `Наша миссия – помогать нашим партнёрам добиваться успеха через применение современных ИТ-решений.`,
  `За 20 лет успешной работы наша команда оказала значительную поддержку корпорации Wiley в ее трансформации из издателя печатных книг и журналов в одного из лидеров на глобальном рынке цифровых образовательных систем.`,
  `Мы готовы применить наш опыт и знания на российском рынке и предоставить нашим партнёрам передовые ИТ-решения для достижения их целей.`,
  `Наша команда – это сплочённый коллектив высококлассных инженеров с многолетним опытом разработки, внедрения и поддержки передовых технологических решений. Большинство наших сотрудников – выпускники ведущих российских ВУЗов со специализацией в сфере информационных технологий.`
]
function AboutDetails() {
  return (
    <div>
      <h2> ООО «Спринтерра» – аккредитованная ИТ-компания, созданная на базе российского технологического центра
        корпорации Wiley. </h2>
      <br/>
      <TextSections sections={sections} />
    </div>
  );
}

export default AboutDetails;
