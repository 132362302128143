import TextSections from "../components/TextSections";

const sections = [
`Платформы, системы и приложения, созданные нашей командой, используются тысячами учёных, научных работников и исследователей, десятками тысяч профессоров и преподавателей и миллионами абитуриентов и студентов в крупнейших учебных заведениях во всём мире, включая США, Канаду, Великобританию, Евросоюз, ОАЭ, Китай, Австралию.`,
`Системы подготовки и публикации контента, разрабатываемые нашими специалистами, известны подавляющему большинству учёных и авторов, когда-либо публиковавших результаты своих исследований в ведущих международных издательствах. Исследователи и авторы имеют доступ к более чем 7,5 миллиона статей из более чем 1 600 журналов и более чем 21 000 онлайн книг.`,
`Электронные образовательные системы, в создании которых наши разработчики играли ведущую роль, внедрены в колледжах и университетах, а также в школах с углубленным изучением предметов. Более 800 000 новых студентов высших и средних учебных заведений ежегодно используют разработанные нами системы электронного обучения для получения знаний и сдачи экзаменов по различным дисциплинам, включая бухгалтерский учёт, физику, химию, менеджмент, математику, анатомию, иностранные языки, географию, здоровое питание.`
]

function OurClients() {
  return (
    <div>
      <div className="about-us-map sm w-full
      block                  md:hidden
      h-[200px] sm:h-[300px] md:h-[300px] lg:h-[700px]

      "></div>
      <div className="about-us-map w-full
      hidden                 md:block
      h-[200px] sm:h-[300px] md:h-[300px] lg:h-[700px]"></div>
      <TextSections sections={sections} />
    </div>
  );
}

export default OurClients;
