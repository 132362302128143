import About from './About'
import Services from "./Services";
import Clients from "./Clients";
import Contact from "./Contact";

function Home() {
  return (
    <>
      <div className="w-full h-screen flex justify-center items-center home-content">
        <div>
          <div className="text-center text-sgray home-header md:text-7xl text-3xl">
            СПРИНТЕРРА
          </div>
          <div className="text-center text-smediumgray italic home-sub-header mt-2">
            <div className="md:text-4xl text-3xl">раскройте потенциал с <br/>передовыми технологиями</div>
          </div>
        </div>
      </div>
      <About/>
      <Services/>
      <Clients/>
      <Contact/>
    </>
  );
}

export default Home;
