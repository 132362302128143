import TextBlock from "./TextBlock";

function DarkBlock({id, header, children, className}) {
  return <TextBlock
    id={id}
    header={header}
    className={`${className ?? ''}`}
    headerClassname="text-sgreen"
    contentClassname="text-sgray"
  >
    {children}
  </TextBlock>
}

export default DarkBlock
