function TextBlock({id, header, children, className, contentClassname, headerClassname}) {
  return <div id={id ?? ''} className={`flex flex-col items-center justify-center ${className ?? ''}`}>
    <div className={`w-11/12 md:w-2/3 mb-4 md:mb-10 ${contentClassname}`}>
      {header ? <h1 className={`w-full text-center mt-2 md:mt-4 pt-4 pb-4 ${headerClassname}`}>{header}</h1> : ''}
      {children}
    </div>
  </div>
}

export default TextBlock
