import { Routes, Route } from "react-router-dom"
import './styles/index.scss';
import Layout from "./Layout"
import Home from "./pages/Home"

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about" element={<Home/>} />
        <Route path="contacts" element={<Home/>} />
        <Route path="clients" element={<Home/>} />
        <Route path="services" element={<Home/>} />
        <Route path="career" element={<Home/>} />
      </Routes>
    </Layout>
  );
}

export default App;
