import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';

function Office() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    './img/office1.jpg',
    './img/office2.jpg',
    './img/office3.jpg'
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <p>
        Офис компании располагается по адресу: Московская обл., г. Королёв, ул. Дзержинского, д. 29, офис №5.
      </p>
      <p className="mt-2">
        В нашем офисе – комфортное и просторное офисное пространство, где есть всё необходимое для работы и делового общения:
      </p>

      <ul className="list-disc list-inside ml-8">
        <li>полностью оборудованные современные рабочие места;</li>
        <li>просторные залы для проведения конференций;</li>
        <li>комнаты для переговоров;</li>
        <li>зоны отдыха;</li>
        <li>кафетерий.</li>
      </ul>

      <div className="parent-grid mt-4">
        {images.map((src, index) => (
            <img
              className={`object-cover h-full ${"div"+(index+1)} ${index===1 && "object-cover"}`}
              src={src}
              onClick={() => openImageViewer(index)}
              key={index}
              alt=""
            />
        ))}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
}

export default Office;
