import TextBlock from "./TextBlock";

function LightBlock({id, header, children, className}) {
  return <TextBlock
    id={id}
    header={header}
    className={`bg-sgray ${className ?? ''}`}
    headerClassname="text-sdarkgreen"
  >
    {children}
  </TextBlock>
}

export default LightBlock
