import TextSections from "../components/TextSections";

const sections = [
  `•	Мы проводим функциональное и нагрузочное тестирование с помощью автоматизированных тестов.`,
  `•	Документируем тест-кейсы и требования. Разрабатываем полную и понятную тестовую документацию.`,
  `•	Используем Jira, Testrail, Confluence, TestComplete, Selenium, JUnit, TestNG, Cypress и другие инструменты и фреймворки для повышения эффективности тестирования.`,
  `•	Управляем процессом и постоянно взаимодействуем с партнёрами. Совместно определяем ключевые вехи и критерии качества для каждой стадии внедрения, готовим соглашение об уровне сервиса (SLA) и предоставляем средства мониторинга для контроля нашей работы.`,
  `•	Предоставляем отчетность по тестированию. Определяем формат и частоту предоставления отчетов и информируем заказчика о статусе работ.`
]

function Testing() {
  return (
    <div>
      <div className="mb-2">
        Тестирование позволяет проверить соответствие реальных и ожидаемых результатов поведения программы, а также
        сделать так, чтобы до пользователей дошла её наиболее качественная и удобная версия.
      </div>
      <div className="mb-2">
        Мы предоставляем многоуровневое тестирование на всех этапах работы над проектом.
      </div>
      <TextSections sections={sections} />
    </div>
  )
    ;
}

export default Testing;
