import SLink from "../components/SLink"
import {useState} from "react";
import DarkBlock from "../components/DarkBlock";

function Contact() {
  const [expandState, setExpand] = useState(false)

  const toggleExpand = () => {
    setExpand(!expandState)
  }

  return (
    <div id="contacts">
      <DarkBlock header="Контакты">
        <div className="w-full text-sgray">
          <div className="flex flex-col items-center justify-center mb-2 mt-4">
            <h2 className="text-3xl">Желаете узнать о нас больше?</h2>
            <div className="text-center mb-2 mt-4">
              Свяжитесь с нами любым удобным<br/>способом и мы ответим на все вопросы.
            </div>

          </div>

          <div className="flex flex-col direction lg:flex-row lg:items-start justify-center mb-2 mt-4">
            <div className="w-full lg:w-1/3 flex flex-row items-start justify-center">
              <div className="w-full lg:w-2/3 text-start">

                <h2 className="text-3xl text-sgreen">Позвонить:</h2>
                Телефон: <SLink URL="tel:+74987190917" text="+7 (498) 719-0-917"/>

                <div className="w-full pic-phone h-[100px] mt-8 hidden md:block"></div>
              </div>
            </div>

            <div className="w-full lg:w-1/3 flex flex-row items-start justify-center">
              <div className="w-full lg:w-2/3 text-start">
                <div className="pic-email"></div>
                <h2 className="text-3xl text-sgreen">Написать:</h2>
                E-mail: <SLink URL="mailto:info@sprinterra.ru" text="info@sprinterra.ru"/>

                <div className="w-full pic-email h-[100px] mt-8 hidden md:block"></div>
              </div>
            </div>

            <div className="w-full lg:w-1/3 flex flex-row items-start justify-center">
              <div className="w-full lg:w-2/3 text-start">

                <div className="">
                  <div className="w-full lg:w-2/3 text-start">
                    <h2 className="text-3xl text-sgreen">Приехать по адресу:</h2>
                    141080, Московская обл.,<br/>
                    г. Королёв,
                    ул. Дзержинского, 29,
                    офис №5.
                  </div>

                  <h2 onClick={toggleExpand}
                      className="mt-8 text-3xl cursor-pointer text-sgreen">{!expandState ? '▼' : '▲'} Найти на
                    карте</h2>

                  <iframe className={!expandState ? 'hidden' : ''} title="map" width="100%" height="300" frameBorder="0"
                          scrolling="no" marginHeight="0"
                          marginWidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D0%B3.%20%D0%9A%D0%BE%D1%80%D0%BE%D0%BB%D0%B5%D0%B2,%20%D1%83%D0%BB.%20%D0%94%D0%B7%D0%B5%D1%80%D0%B6%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B3%D0%BE,%2029.+(Sprinterra)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DarkBlock>

    </div>
  );
}

export default Contact;


