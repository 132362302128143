
function KeyDirectionsBlock({number, items, header, className}) {
return  <div className={`key-directions-block  ${className ?? 'mb-14'}`}>
  <h2 className="mb-8 text-2xl">{header}</h2>
  <div className="flex align-left justify-start text-sdarkgreen">
    <div className="hidden md:block key-directions-number text-slightgreen">{number}</div>
    <ul className="md:ml-8">
      {items.map(item => <li className="leading-7">{item}</li>)}
    </ul>
  </div>
</div>
}

export default KeyDirectionsBlock
