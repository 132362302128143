import SLink from "../components/SLink"
import DarkBlock from "../components/DarkBlock";

function Clients() {
  return (
    <div id="clients">
      <DarkBlock header="Клиенты">
        <div
          className="flex flex-col-reverse direction md:flex-row align-center justify-start items-center text-sgray">
          <div className="mb-2 mt-4 md:w-2/3">
            <div className="mb-2">
              Wiley – международная издательская компания, которая специализируется на выпуске академических изданий и
              образовательных материалов для студентов и преподавателей высших учебных заведений, исследователей, учёных.
              Компания Wiley издаёт книги, журналы, учебники, энциклопедии в печатном и электронном виде, предоставляет
              доступ к продуктам и услугам в интернете.
            </div>

            <div className="mb-2">
              Наши сотрудники принимали активное участие в разработке популярных продуктов для компании Wiley.
            </div>

            <div className="mb-2">
              Исследовательские библиотеки:
              <ul className="md:ml-8">
                <li>• <SLink URL="https://onlinelibrary.wiley.com/"/></li>
                <li>• <SLink URL="https://oable.org/"/></li>
                <li>• <SLink URL="https://www.cochranelibrary.com/"/></li>
                <li>• <SLink URL="https://www.wiley-vch.de/de/"/></li>
              </ul>
            </div>

            Приложения для публикации научных трудов:
            <ul className="md:ml-8">
              • <SLink URL="https://authorservices.wiley.com/"/>
            </ul>
            Образовательные системы для высшего, среднего и профессионального образования:

            <ul className="md:ml-8">
              <li>• <SLink URL="https://www.wileyplus.com/"/></li>
              <li>• <SLink URL="https://www.efficientlearning.com/"/></li>
            </ul>

          </div>

          <div className="flex justify-center w-1/3">
            <img src="./img/Wiley_Logo_white.png" alt="Wiley"/>
          </div>
        </div>
      </DarkBlock>
    </div>
  );
}

export default Clients;
