function WhyUs() {
  return (
    <div>
      <ul className="list-disc list-inside">
          <li>Профессиональная команда экспертов в области ИТ: архитекторы, аналитики, инженеры, разработчики ПО и другие технические специалисты.</li>
          <li>Более чем 20-летний опыт создания законченных комплексных решений в ИТ-сфере.</li>
          <li>Опыт организации 24/7/365 поддержки безотказной работы наших решений.</li>
          <li>Высокая инженерная и управленческая культура ведения проектов, позволяющая снизить контроль со стороны заказчиков.</li>
          <li>Мы несем ответственность за конечный результат.</li>
      </ul>
    </div>
  );
}

export default WhyUs;
