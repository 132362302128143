import KeyDirectionsBlock from "../components/KeyDirectionsBlock";

function ProductDev() {
  return (
    <div>
      <KeyDirectionsBlock
        number="1"
        header="Совместно с нашими партнёрами определяем техническое задание и целевую аудиторию"
        items={[
          '•\tАнализируем потребности рынка.',
          '•\tСогласовываем общее видение будущего продукта.',
        ]}
      />
      <KeyDirectionsBlock
        number="2"
        header="Устанавливаем партнерские взаимоотношения и обязательства:"
        items={[
          '•\tМы создаем команду разработчиков с выделенным менеджером ресурсов.',
          '•\tНаша компания позаботится, чтобы в команду вошли необходимые специалисты с соответствующим опытом, знаниями и навыками.',
          '•\tМы берём на себя партнерские обязательства, включая формирование модели взаимодействия, согласование сроков реализации, поддержку продукта на этапе эксплуатации. Определяем роли и обязанности сторон.',
        ]}
      />
      <KeyDirectionsBlock
        number="3"
        header="Вовлекаем команды и запускаем разработку:"
        items={[
          '•\tВместе с командой клиента выстраиваем эффективную коммуникацию (определяем каналы и формат взаимодействия, роли и обязанности, инструменты и периодичность отчетности).',
          '•\tОбговариваем этапы и сроки реализации проекта.',
          '•\tОбеспечиваем плавный старт.',
        ]}
      />
      <KeyDirectionsBlock
        number="4"
        header="Поддерживаем разработку продукта вплоть до достижения поставленной цели:"
        items={[
          '•\tИспользуем прозрачный процесс: ежедневная отчетность в первом инкременте проекта, далее демонстрация результатов с заданной периодичностью.',
          '•\tС помощью менеджера проекта вносим необходимые изменения и исправления в организацию работ, коммуникацию с партнёрами, реализацию продукта.',
          '•\tОбеспечиваем инкрементальную поставку небольших составляющих продукта с периодической инспекцией промежуточных результатов.',
          '•\tОбеспечиваем гибкость при внесении изменений.'
        ]}
      />
      <KeyDirectionsBlock
        number="5"
        header="Переходим к гибкому и стратегическому партнерству."
        className="mb-0"
        items={[
          '•\tАдаптация размера и специализации команды разработчиков при необходимости.',
          '•\tТехническая поддержка продукта после его запуска в эксплуатацию.',
          '•\tПостоянное улучшение и расширение функциональных возможностей продукта на основе пожеланий партнёра и мониторинга активности пользователей.',
        ]}
      />
    </div>)
}

export default ProductDev;
