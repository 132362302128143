const sections = [
`Наши специалисты разработают архитектуру, техническое решение и дизайн вашего будущего продукта. Мы создадим или поможем в создании плана разработки и поставки законченного решения потребителю.`,
`Наша команда возьмёт на себя создание комплексного решения, либо выступит в качестве исполнителя по разработке отдельных компонентов программного обеспечения или приложений.`,
]

function Development() {
  return (
    <div>
      {sections.map(section=>(<div className="mb-2">{section}</div>))}
    </div>
  );
}

export default Development;



