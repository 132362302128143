import './styles/main.scss';
import { Link } from 'react-router-dom';
import menu from './data/menu';
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import MenuWithScroll from './components/MenuWithScroll'

function Layout({children}) {
  const location = useLocation();
  const [menuVisible, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menuVisible)
  const hideMenu = () => setMenu(false)

  useEffect(() => {
    if(location.pathname === "/") {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      const yOffset = -140;
      const element = document.getElementById(location.pathname.replace("/",""));
      if(element) {
        const y = element.offsetTop + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }


  }, [location]);


  return (
    <div className="flex w-full h-full flex-col bg-sdarkgreen">

      <nav className="w-full fixed">
          <Link to="/">
          <div className="logo">
          </div>
          </Link>

          <div className="flex justify-end items-center h-[140px] full-menu__container
          lg:justify-center
          ">
            <div className="flex w-100">
              <div className="hidden sm:flex space-x-1">
                {menu.map(({ title, route}) => (<Link
                      to={route}
                      rel="preload"
                      className={`py-4 px-2 text-white whitespace-nowrap duration-200 transition-colors hover:text-sgreen
                      
                      ${location.pathname === route ? "text-sgreen" : ""}`}
                    >
                      {title}
                    </Link>
                ))}
                {/*<MenuWithScroll />*/}
              </div>
            </div>
            <div className="sm:hidden mr-5">
              <button className="outline-none mobile-menu-button" onClick={toggleMenu}>
                <svg className={`w-12 h-12 text-white hover:text-sgreen ${menuVisible?"text-sgreen":""}`}
                     x-show="!showMenu"
                     fill="none"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        <div className={`${menuVisible?"visible":""} lg:hidden mobile-menu mt-0 bg-sdarkgreen text-sgray`}>
          <ul className="">
            {menu.map(({ title, route}) => (<li className={location.pathname === route ? "active" : ""}><Link
                to={route}
                className="block px-2 py-4 hover:bg-green-500 transition duration-300"
                onClick={hideMenu}
              >
                {title}
              </Link></li>
            ))}
          </ul>
        </div>
      </nav>

      <main className="w-full">
        {children}
      </main>

      <footer className="w-full
              left-0 bottom-0
              flex flex-col justify-center items-center
              mt-auto
              bg-black
              text-white
              text-xs
              ">
        <div className="p-5 flex align-center items-center flex-col">
          <div> © {new Date().getFullYear()} ООО «Спринтерра». Все права защищены.</div>
          <div className="text-sm">
            <a className="transition-colors hover:text-sgreen" href="/doc/Summary_data_on_the_results_of_a_special_assessment_of_working_conditions.pdf" target="_blank" download>
              Сводные данные о результатах проведения специальной оценки условий труда
            </a>
          </div>
        </div>

      </footer>
    </div>
  );
}

export default Layout;
