import TextSections from "../components/TextSections";

const sections = [
  'Мы привержены принципам и ценностям гибкой методологии разработки ИТ-продуктов.',
'Наша команда разрабатывает программное обеспечение с использованием Agile методологий и фреймворков, включая Scrum, Kanban для небольших команд, SAFe, Less, Nexus для более крупных и сложных проектов, в реализации которых задействовано большое количество команд и многоуровневая организация.',
'На всем протяжении цикла разработки продукта наша компания обеспечивает прозрачность процессов и работ, активное сотрудничество с заказчиком на каждом этапе, регулярный обзор достигнутых результатов, сфокусированность на создании результата, который будет востребован пользователями и принесёт прибыль создателям. Мы всегда готовы к быстрому реагированию на изменения условий рынка и требований заказчика.',
'Сфокусированность, открытость, смелость, ответственность, уважение – эти принципы лежат в основе нашей деятельности.'
]

  function AgileMethod() {
  return (
    <div>
      <TextSections sections={sections} />
    </div>
  );
}

export default AgileMethod;
