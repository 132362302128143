const menu = [
  {
    title: 'О НАС',
    route: '/about'
  },
  {
    title: 'СЕРВИСЫ',
    route: '/services'
  },
  {
    title: 'КЛИЕНТЫ',
    route: '/clients'
  },
  {
    title: 'КОНТАКТЫ',
    route: '/contacts'
  }
]

export default menu
