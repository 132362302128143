import Development from "./Development";
import ProductDev from "./ProductDev";
import Testing from "./Testing";
import AgileMethod from "./AgileMethod";
import LightBlock from "../components/LightBlock";
import DarkBlock from "../components/DarkBlock";

function Services() {
  return (
    <div>
      <DarkBlock id="services" header="Сервисы">
        <Development/>
      </DarkBlock>

      <LightBlock header="Как строится работа">
        <ProductDev/>
      </LightBlock>

      <LightBlock header="Тестирование">
        <Testing/>
      </LightBlock>

      <LightBlock header="Гибкие методологии разработки">
        <AgileMethod/>
      </LightBlock>
    </div>
  );
}

export default Services;
