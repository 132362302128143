import KeyDirections from "./KeyDirections";
import AboutDetails from "./AboutDetails";
import OurClients from "./OurClients";
import Office from "./Office";
import WhyUs from "./WhyUs";
// import Management from "./Management";
import LightBlock from "../components/LightBlock";
import DarkBlock from "../components/DarkBlock";

function About() {
  return (
    <div id="about">
      <DarkBlock header="О Нас">
        <AboutDetails/>
      </DarkBlock>

      <div className="w-full h-[50px] md:h-[120px] bg-pink pic-universities text-white"></div>

      <LightBlock header="Наши пользователи">
        <OurClients/>
      </LightBlock>

      <LightBlock header="Ключевые направления">
        <KeyDirections/>
      </LightBlock>

      <LightBlock header="Наши преимущества">
        <WhyUs/>
      </LightBlock>

      <LightBlock header="Офис компании в России">
        <Office/>
      </LightBlock>

      {/*<LightBlock header="Руководство компании">*/}
      {/*  <Management/>*/}
      {/*</LightBlock>*/}
    </div>
  );
}

export default About;
