import KeyDirectionsBlock from "../components/KeyDirectionsBlock";

function KeyDirections() {
  return (
    <div>
      <KeyDirectionsBlock
        number="1"
        header="Системы подготовки и публикации контента."
        items={[
          'a. Расширенный поиск издательств и журналов для публикации статей.',
          'b. Помощь в подготовке материалов для публикации.',
          'c. Автоматизация поиска рецензентов и создания рецензии на статью.',
          'd. Публикация статей с научными исследованиями в ведущих научных журналах по всему миру.'
        ]}
      />

      <KeyDirectionsBlock
        number="2"
        header="Электронные образовательные системы."
        items={[
          'a. Собственная разработка системы управления обучением (LMS) с нуля.',
          'b. Интеграция отдельных сервисов собственной разработки в стороннюю систему управления обучением (LMS), например Instructure Canvas, Moodle, Blackboard или другую.',
          'c. Учебник в виде электронной книги и дополнительные учебные материалы в различных мультимедиа форматах.',
          'd. Богатый набор вопросов в тест-банках для создания домашних заданий, тестов, проверочных работ,экзаменов.',
          'e. Автоматизированная система оценки знаний с поддержкой более пятидесяти различных типов вопросов,включая Molecular Drawing, Math Symbolic Notation, Chem Symbolic Notation, Drag’n’Drop, AlgorithmicQuestions, Multistep Questions.',
          'f. Автоматизированная система адаптивной оценки знаний.',
          'g. Электронный журнал оценки успеваемости.',
          'h. Собственная система и инструменты подготовки и обновления контента.'
        ]}
      />

      <KeyDirectionsBlock
        number="3"
        className="mb-0"
        header="Разработка решений в области информационных технологий на заказ."
        items={[
          'a. Многофункциональные web-приложения, включая интернет- и интранет- решения.',
          'b. Интеграционные решения.',
          'c. Решения на основе сервисно-ориентированной архитектуры и интеграционных шин.',
          'd. Высоконагруженные распределенные отказоустойчивые решения.',
          'e. Автоматизация бизнес-процессов.'
        ]}
      />

    </div>
  );
}

export default KeyDirections;
